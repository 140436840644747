// icons
import { trash, close, } from 'ionicons/icons';

import { ref } from 'vue';
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { actionSheetController } from '@ionic/vue';
import { useI18n } from 'vue-i18n';

export function usePhotoGallery() {
  const { t } = useI18n();
  const photos = ref<Photo[]>([]);

  const takePhoto = async () => {
    const cameraPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      source: CameraSource.Prompt,
      quality: 80,
    });
    const fileName = new Date().getTime() + '.jpeg';
    const image = { filepath: fileName, base64Data: `data:image/jpeg;base64,${cameraPhoto.base64String}` }
    photos.value = [image, ...photos.value];
  };

  const deletePhoto = async (photo: Photo) => {
    photos.value = photos.value.filter(p => p.filepath !== photo.filepath);
  };

  const showActionSheet = async (photo: Photo) => {
    const actionSheet = await actionSheetController.create({
      header: 'Photos',
      buttons: [{
        text: t('delete'),
        role: 'destructive',
        icon: trash,
        handler: () => {
          deletePhoto(photo);
        }
      },
      {
        text: t('cancel'),
        icon: close,
        role: 'cancel',
      }]
    });
    await actionSheet.present();
  }

  return {
    photos,
    takePhoto,
    showActionSheet,
  };
}

export interface Photo {
  filepath?: string;
  base64Data?: string;
}
