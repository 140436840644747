
// Vue reactivity
import { computed, ref } from 'vue';

// icons
import { thumbsUpOutline, thumbsDownOutline, create, trash, ellipsisHorizontal, ellipsisVertical, ban } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonRow, IonCol, IonSpinner, IonItem, IonLabel, IonChip, IonIcon, IonBackButton,
        IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
        IonBadge, IonTextarea, IonButton, IonButtons, IonAvatar,
        IonRefresher, IonRefresherContent, IonNote,
        modalController, alertController, loadingController, popoverController} from '@ionic/vue';
import PostModal from '@/components/PostModal.vue';
import PostActionMenu from '@/components/PostActionMenu.vue';
import ImageSlides from '@/components/ImageSlides.vue';

// API services
import PostService from '@/services/PostService';
import { utils } from '@/composables/utils';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: 'PostDetailPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
                IonGrid, IonRow, IonCol, IonSpinner, IonItem, IonLabel, IonChip, IonIcon, IonBackButton,
                IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
                IonBadge, IonTextarea, IonButton, IonButtons, IonAvatar,
                IonRefresher, IonRefresherContent, IonNote, ImageSlides, },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const route = useRoute();
    const currPostId = route.params.id; // post ID

    // 1. declare state variables (ref to make them reactive)
    const currUser = computed(() => store.state.user);
    const userLoggedIn = computed(() => store.state.loggedIn);
    const loading = computed(() => store.state.loadingAppPublicData);
    const loadingUser = computed(() => store.state.loadingUser);
    const post = computed(() => store.getters.getPostById(currPostId));
    const postComments = ref<any>([]);
    const loadingPostComments = ref(false);
    const newCommentContent = ref("");

    // methods or filters
    const parentPath = route.params.parentPath; // may be from user posts page
    const router = useRouter();
    const { formatDate, presentToast, isPostAuthorBanned } = utils();
    
    const canUpdatePost = (post: any) => {
      return post.userId == currUser.value.id;
    }
    const deletePost = async (post: any) => {
      const alert = await alertController.create({
        header: t('PostPage.confirmDelete'),
        message: t('PostPage.confirmDeletePost'),
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: t('PostPage.deletePost'),
            handler: async () => {
              const loading = await loadingController.create({});
              await loading.present();
              await PostService.deletePost(post.id, post.image);
              store.commit('deletePost', post.id);
              loading.dismiss();
              presentToast( t('PostPage.postDeleted'), 2000 );
              router.push('/posts');
            },
          },
        ],
      });
      return alert.present();
    }

    const likePost = (post: any) => {
      if (userLoggedIn.value == true) {
        post.likeCount++;
        PostService.updatePostReaction(post.id, "like");
      }
    }
    const dislikePost = (post: any) => {
      if (userLoggedIn.value == true) {
        post.dislikeCount++;
        PostService.updatePostReaction(post.id, "dislike");
      }
    }
    const likePostComment = (comment: any) => {
      if (userLoggedIn.value == true) {
        comment.likeCount++;
        PostService.updatePostCommentReaction(comment.id, "like");
      }
    }
    const dislikePostComment = (comment: any) => {
      if (userLoggedIn.value == true) {
        comment.dislikeCount++;
        PostService.updatePostCommentReaction(comment.id, "dislike");
      }
    }

    const fetchPostComments = (postId: any, e: any = null) => {
      loadingPostComments.value = true;
      PostService.getPostComments(postId).then(res => {
        loadingPostComments.value = false;
        postComments.value = res;
        if (e) e.target.complete(); // dismiss ion-refresher
      });
    }
    const fetchLatestPost = (postId: any, e: any = null) => {
      PostService.getPostById(postId).then(res => {
        store.commit('updatePost', res);
      });
      fetchPostComments(postId);
    }

    const addNewPostComment = async (post: any) => {
      if (newCommentContent.value) {
        // call API to add post comment
        const loading = await loadingController.create({});
        await loading.present();
        PostService.addNewPostComment(post.id, newCommentContent.value).then(res => {
          loading.dismiss();
          newCommentContent.value = ""; // reset textarea value
          postComments.value.push(res.data[0]); // add the new comment to the list
        });
      }
    }
    
    const openEditPostModal = async () => {
      const modal = await modalController.create({
        component: PostModal,
        componentProps: { editingPost: post },
      });
      modal.onDidDismiss().then((data) => {
        fetchLatestPost(currPostId);
      })
      return modal.present();
    }

    const openPopover = async (post: any, ev: any, isPostComment = false) => {
      const options: any = {
        component: PostActionMenu,
        event: ev,
        componentProps: { post, isPostComment },
      };
      const popover = await popoverController.create(options);
      await popover.present();
    };

    fetchPostComments(currPostId);

    // 2. fetch data from API and store data in state variables (optionally with polling)
    //const polling = setInterval(fetchLatestPost, 5000, currPostId);
    //onUnmounted(() => {
    //  clearInterval(polling);
    //});

    // 3. return variables & methods to be used in template HTML
    return {
      t,

      // icons
      thumbsUpOutline, thumbsDownOutline, create, trash, ellipsisHorizontal, ellipsisVertical, ban,

      // variables
      userLoggedIn, currPostId, parentPath, loading, loadingPostComments, loadingUser,
      post, postComments, newCommentContent,

      // methods
      fetchLatestPost, formatDate, likePost, dislikePost,
      openEditPostModal, deletePost, canUpdatePost,
      likePostComment, dislikePostComment, addNewPostComment,
      openPopover, isPostAuthorBanned,
    }
  },
}
