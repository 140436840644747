
// Vue reactivity
import { ref, reactive, defineComponent, watchEffect } from 'vue';

// icons
import { thumbsUpOutline, thumbsDownOutline, add, close, camera } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonSpinner, IonItem, IonLabel, IonIcon,
        IonThumbnail, IonAvatar, IonButtons, IonButton, IonInput, IonTextarea, modalController, loadingController,
        IonCol, IonRow, IonList, IonListHeader, IonFabButton, IonCard, } from '@ionic/vue';

// API services
import PostService from '@/services/PostService';

import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { usePhotoGallery } from '../composables/usePhotoGallery';

export default defineComponent({
  name: 'PostModal',
  props: {
    editingPost: null,
  },
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter,
                IonSpinner, IonItem, IonLabel, IonIcon,
                IonThumbnail, IonAvatar, IonButtons, IonButton, IonInput, IonTextarea,
                IonCol, IonRow, IonList, IonListHeader, IonFabButton, IonCard, },
  setup(props) {
    // 1. declare state variables (ref to make them reactive)
    const { photos, takePhoto, showActionSheet } = usePhotoGallery();

    const post = reactive({
      id: null,
      title: "",
      content: "",
    });
    const isEditingPost = ref(false);

    if (props.editingPost) { // editing post
      isEditingPost.value = true;
      watchEffect(() => {
        const { id, title, content } = props.editingPost.value;
        post.id = id;
        post.title = title;
        post.content = content;
      })
    }

    // methods or filters
    const { t } = useI18n();
    const { formatDate, presentToast } = utils();

    const closeModal = async (data: any = {}) => {
      await modalController.dismiss(data);
    };

    const createNewPost = async (title: string, content: string) => {
      const loading = await loadingController.create({});
      await loading.present();
      const res = await PostService.addNewPost(title, content, photos.value);
      loading.dismiss();
      presentToast( t('PostPage.successPublishPost'), 2000 );
      closeModal({ newPostCreated: true });
    };

    const updatePost = async() => {
      const loading = await loadingController.create({});
      await loading.present();
      const res = await PostService.updatePost(post.id, post.title, post.content);
      loading.dismiss();
      presentToast( t('PostPage.successUpdatePost'), 2000 );
      closeModal();
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      thumbsUpOutline, thumbsDownOutline, add, close, camera,

      // variables
      post, isEditingPost,
      photos,

      // methods
      t, formatDate, createNewPost, updatePost, closeModal,

      takePhoto, showActionSheet
    }
  }
});
