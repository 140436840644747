import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createBlock(_component_ion_list, null, {
    default: _withCtx(() => [
      (!$setup.isPostAuthorBanned($props.post))
        ? (_openBlock(), _createBlock(_component_ion_item, {
            key: 0,
            lines: "full",
            detail: "false",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.reportPost($props.post, $props.isPostComment)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                icon: $setup.flag,
                slot: "start"
              }, null, 8, ["icon"]),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.t('PostPage.report')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      ($setup.isPostAuthorBanned($props.post))
        ? (_openBlock(), _createBlock(_component_ion_item, {
            key: 1,
            lines: "none",
            detail: "false",
            onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.unbanPostAuthor($props.post))),
            button: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.t('PostPage.unblock')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_ion_item, {
            key: 2,
            lines: "none",
            detail: "false",
            onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.banPostAuthor($props.post))),
            button: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                icon: $setup.ban,
                slot: "start"
              }, null, 8, ["icon"]),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.t('PostPage.block')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}