import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            (_ctx.isEditingPost)
              ? (_openBlock(), _createBlock(_component_ion_title, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('PostPage.editPost')), 1)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_ion_title, { key: 1 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('PostPage.newPost')), 1)
                  ]),
                  _: 1
                })),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  slot: "icon-only",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { fullscreen: true }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, { position: "stacked" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('PostPage.postTitle')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_input, {
              modelValue: _ctx.post.title,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.post.title) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, { position: "stacked" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('PostPage.postContent')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_textarea, {
              modelValue: _ctx.post.content,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.post.content) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        (!_ctx.isEditingPost)
          ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_list_header, { color: "primary" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('PostPage.postImage')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.photos, (photo) => {
                      return (_openBlock(), _createBlock(_component_ion_col, {
                        size: "4",
                        key: photo
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("img", {
                            src: photo.base64Data,
                            onClick: ($event: any) => (_ctx.showActionSheet(photo))
                          }, null, 8, _hoisted_1)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128)),
                    _createVNode(_component_ion_col, { size: "4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_card, { class: "photo-card ion-align-items-center ion-justify-content-center ion-text-center" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_fab_button, {
                              class: "take-photo-fab-btn",
                              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.takePhoto()))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.camera }, null, 8, ["icon"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_ion_footer, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            (_ctx.isEditingPost)
              ? (_openBlock(), _createBlock(_component_ion_button, {
                  key: 0,
                  expand: "block",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updatePost()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('PostPage.updatePost')), 1)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_ion_button, {
                  key: 1,
                  expand: "block",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.createNewPost(_ctx.post.title, _ctx.post.content)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('PostPage.publishPost')), 1)
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}