
// icons
import { flag, ban } from 'ionicons/icons';

// components
import { IonButton, IonIcon, IonBadge, IonLabel, IonList, IonItem, alertController, loadingController, popoverController, } from '@ionic/vue';

import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';

import PostService from '@/services/PostService';

export default {
  props: ["post", "isPostComment"],
  components: { IonButton, IonIcon, IonBadge, IonLabel, IonList, IonItem, },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const { presentToast, isPostAuthorBanned } = utils();

    const onDismiss = async () => {
      try {
        await popoverController.dismiss();
      } catch (e) {
        return false;
      }
    }
    const presentAlert = async (message: any = "", callback: any) => {
      onDismiss();
      const alert = await alertController.create({
        message,
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: t('confirm'),
            handler: callback,
          }
        ]
      });
      return alert.present();
    }
    
    const reportPost = async (post: any, isPostComment: any) => {
      onDismiss();
      // alert confirm (report objectionable content)
      // acknowledgement (will handle within 24 hours)
      const alert = await alertController.create({
        header: t('PostPage.report'),
        message: t('PostPage.confirmReport'),
        inputs: [
          {
            name: 'description',
            type: 'textarea',
            placeholder: t('PostPage.reportDescription')
          },
        ],
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: t('PostPage.report'),
            handler: async (value) => {
              const loading = await loadingController.create({});
              await loading.present();
              await PostService.reportPost(post.id, value.description, isPostComment);
              loading.dismiss();
              presentToast(t('PostPage.reportedPost'));
            },
          }
        ]
      });
      return alert.present();
    }

    const banPostAuthor = async (post: any) => {
      presentAlert(t('PostPage.confirmBanUser').replace("user", post.username), async() => {
        const loading = await loadingController.create({});
        await loading.present();
        await PostService.banUser(post.userId, post.id);
        loading.dismiss();
        presentToast(t('PostPage.blockedUser').replace('user', post.username));
        store.commit('addBlockedUser', post.userId);
      });
    }

    const unbanPostAuthor = async (post: any) => {
      presentAlert(t('PostPage.confirmUnbanUser').replace("user", post.username), async() => {
        const loading = await loadingController.create({});
        await loading.present();
        await PostService.unbanUser(post.userId);
        loading.dismiss();
        presentToast(t('PostPage.unblockedUser').replace('user', post.username));
        store.commit('removeBlockedUser', post.userId);
      })
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      flag, ban,

      // variables

      // methods
      t, isPostAuthorBanned,
      banPostAuthor, unbanPostAuthor,
      reportPost,
    }
  }
}
